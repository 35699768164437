body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-collapse .ant-collapse-content-box {
  padding: 0;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.image-upload-container > .ant-upload {
  width: inherit;
  height: inherit
}

.image-upload-container > .ant-upload > span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

.hidden-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hidden-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #rbgcp-wrapper {
    /*background-color: white !important;*/
    /*color: black !important;*/
  }
}