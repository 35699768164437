
.screen1Header{
font-family: 'Inter', sans-serif;
font-weight: 700;
margin-top: -50px;
}
.screen2Header{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  
    }
.screenDescription{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 30px !important;
}
.screen3Description{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 30px !important;
    margin-top: 100px;
}
.onboarding-card{
    margin: 75px;

  
}
.onboarding-next-button{
    margin-top: 100px !important;
    background-color: #006DAA !important;
    border-radius: 5px;
    border-color: #006DAA !important;
   box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.14);
   font-family: 'Inter', sans-serif;
   font-weight: 700;

}
.onboarding-next-button:hover{
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.28);
    background: #006daa;
    border: 1px solid transparent;
  }
 
.onboardingBtnBox{
    display: flex !important;
    align-items: center !important;
    justify-content:center !important;
  
}
.onboardingIndicator{
    width: 54px;
height: 25px;
background: #006DAA;
border-radius: 100px;
transform: matrix(1, 0, 0, -1, 0, 0);
margin-top: -80px;

}
.indicatorColumn{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.stepsContainer{
    margin-top: -30px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .screen1Header{
       margin-top: 50px;
       font-size: 30px !important;
      
    }
    .onboarding-card{
        margin: 20px !important;
    }
    .screen2Header{
        margin-top: 50px;
        font-size: 30px !important;
    }
    .screenDescription{
        font-size: 25px !important;
    }
    .imageContainer{
        display: flex !important;
        justify-content: center !important;
    }
    .screen3Description{
        font-size: 22px !important;
        margin-top: 35px ;
    }
    .onboardingBtnBox{
        padding-right: 0px !important;
       
    }
    .onboarding-next-button{
        margin-top: 50px !important;
    
    
    }
    .stepsContainer{
        margin-top: 10px;
        margin-bottom: 10px;
    }
  }
@media only screen and (max-width: 480px) {
   .screen1Header{
       font-size: 20px !important;
   }
   .screen2Header{
    font-size: 20px !important;
   }
   .onboarding-card{
    margin: 15px;
 
}

.screenDescription{
    font-size: 16px !important;
}
.screen3Description{
    font-size: 17px !important;
    margin-top: 40px ;
}
.onboardingBtnBox{
    padding-right: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content:center !important;
}
.onboarding-next-button{
    margin-top: 20px !important;
    font-size: 15px !important;


}
.onboardingIndicator{
    margin-top: 0px;
}
.stepsContainer{
    margin-top: 10px;
    /* margin-bottom: 10px; */
}

  }
  @media only screen and (max-width: 1023px) {
    .screen1Header{
       margin-top: 50px;
    }
    
    
    .imageContainer{
        display: flex !important;
        justify-content: center !important;
    }
    .indicatorColumn{
        display: none !important;
    }
  }
  @media only screen and (min-width: 769px) and (max-width:1023px) {
    .onboardingBtnBox{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding-right: 0px !important;
    }

}
  @media only screen and  (max-width:1440px) {
    .onboardingBtnBox{
      
        padding-right: 40px !important;
    }

}
@media only screen and (min-width: 1250px) and (max-width:1358px) {
    .onboardingBtnBox{
      
        padding-right:100px !important;
    }

}
@media only screen and (min-width: 768px) and (max-width:1317px) {
    .onboarding-card{
        margin: 25px;
    }

}
@media only screen and (min-width: 769px) and (max-width:1317px) {
  .stepsContainer{
      margin-top: 8px;  
        margin-top: 8px;
        margin-bottom: 10px;
    }
}
@media only screen and (min-width: 769px) and (max-width:1440px) {
  
    .screen1Container{
        margin-top: 50px !important;
    }
  
  }