.helperVideo
  top: 0
  border-radius: 20px !important
  text-align: left
  vertical-align: middle
  max-width: 650px !important
  width: 650px !important
  padding: 20px !important
  max-height: 80vh !important
  min-height: 400px !important
  overflow: hidden
  z-index: 20000 !important

.playerDivComponent
  width: 100% !important
  max-height: 580px !important