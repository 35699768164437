.connected
  border: 6px solid #00AA63 !important

.platformCard
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  border-radius: 6px

.disconnect
  background: #B00020 !important
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.14)
  border-radius: 6px
  font-family: Inter, 'sans-serif'
  font-style: normal
  font-weight: bold
  font-size: 18px
  line-height: 22px