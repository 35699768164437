.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav
  margin-bottom: 0 !important

.ant-tabs-tab
  background-color: #111A22 !important
  color: #FFFFFF !important
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)
  border-radius: 6px
.ant-tabs-tab.ant-tabs-tab-active
  background-color: #fff !important
  color: #000 !important
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)
  border-radius: 6px
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn
  color: #000 !important