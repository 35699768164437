@import "~bulma/sass/utilities/initial-variables.sass"
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&Open+Sans:wght@300;400;500;600&Inter:wght@300;400;500;600;700&display=swap')
@import '~react-markdown-editor-lite/lib/index.css'
@import '~react-linear-gradient-picker/dist/index.css'
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

$background: #FFFFFF
$vodka-color: #B8C0FF
$content-wrapper-bg: #F3F3F3
$mirage: #111A22
$verdigris: #28AFB0

$primary-font: 'Roboto', sans-serif
$family-primary: 'Roboto', sans-serif

.is-font-primary
  font-family: $primary-font !important

.content-layout-background
  background-color: $content-wrapper-bg !important
  background-size: cover !important

.content-layout-background-white
  background-color: white !important

$pricing-item-background: $white
$pricing-item-color: $black
$pricing-price-background: $white
$pricing-header-background: $white
$pricing-footer-background: $white
$pricing-item-border: 0 solid black
$pricing-item-color: $black

@import "~video-react/styles/scss/video-react"
@import '~bulma'
@import "~bulma-extensions/bulma-steps/dist/css/bulma-steps.min.css"
@import "~bulma-pricingtable"
@import "~bulma-slider"
@import "~antd/dist/antd.css"
@import "~slick-carousel/slick/slick.scss"
@import "~slick-carousel/slick/slick-theme.scss"
@import "~react-sliding-pane/dist/react-sliding-pane.css"

@import "src/Dashboard/Settings/MarketingReport/Report"
@import "src/Dashboard/Community/community"
@import "src/Dashboard/Dashboard"

.sec-md,.sec-html
  padding: 0.3rem 0.1rem !important

.button-wrap
  .button
    padding: 0 !important

.is-fullwidth
  width: 100% !important

.pricing-plan
  border: 0 !important
  .plan-item
    text-transform: uppercase !important

.onboarding-main
  //background: url("../Common/Images/onboarding-bg.svg") no-repeat center center fixed
  background-color: #bfc1fc
  -webkit-background-size: cover
  -moz-background-size: cover
  -o-background-size: cover
  background-size: cover

.onboarding-card
  background-color: #111A22
  border-radius: 14px

// Quick Start Steps
.quick-start
  .ant-steps-item.ant-steps-item-finish
    .ant-steps-item-container
      .ant-steps-item-icon
        border-color: #00AA63
        background: #00AA63
        .ant-steps-icon
          color: #fff !important
          background: #00AA63
      .ant-steps-item-content
        .ant-steps-item-title
          color: #00AA63
          font-weight: bold
  .ant-steps-item.ant-steps-item-process
    .ant-steps-item-container
      .ant-steps-item-content
        .ant-steps-item-title
          color: #1890ff
          font-weight: bold
  .ant-steps-item.ant-steps-item-wait
    .ant-steps-item-container
      .ant-steps-item-icon
        background: #C4C4C4
        .ant-steps-icon
          color: #111A22 !important
      .ant-steps-item-content
        .ant-steps-item-title
          color: #000000
          font-weight: bold

.has-background-verdigris
  background-color: $verdigris !important
  color: white !important

.has-background-verdigris.is-outlined
  background-color: $white !important
  color: $verdigris !important
  border: 3px solid $verdigris !important

.react-switch-handle
  border: 1px solid #AAA !important

.react-main-modal
  top: 0
  border-radius: 20px !important
  text-align: left
  vertical-align: middle
  max-width: 1000px !important
  width: 980px !important
  padding: 20px !important
  max-height: 80vh !important
  min-height: 400px !important
  overflow: inherit !important
.react-main-modal.narrow
  max-width: 400px !important
  width: 320px !important
  padding: 10px !important
  max-height: 60vh !important
  min-height: 300px !important
  overflow: inherit !important

.react-main-modal-child
  z-index: 499 !important

.ReactModal__Overlay.ReactModal__Overlay--after-open
  display: flex !important
  flex-direction: column !important
  justify-content: center !important
  align-items: center !important
  background-color: rgba(0, 0, 0, 0.45) !important
  z-index: 399
  overflow: auto
.ReactModal__Overlay.dark.ReactModal__Overlay--after-open
  background-color: rgba(0, 0, 0, 0.8) !important

.ant-table-column-title
  z-index: 0 !important

.slick-prev:before, .slick-next:before
  color: #0a0a0a !important

.selected-border
  border: 3px solid #00AA63 !important
  border-radius: 10px

.ant-collapse-content > .ant-collapse-content-box
  padding: 8px