//  Scoll bar
$primary: black
$scroll-width: 6px
$scrolltrack-bg: $primary
$scrollthumb-bg: white
$scrollthumb-border-radius: 20px
$scrollthumb-border: 2.5px solid $primary

//body::-webkit-scrollbar
  width: $scroll-width

//body::-webkit-scrollbar-track
  background: $scrolltrack-bg

//body::-webkit-scrollbar-thumb
  background-color: $scrollthumb-bg
  border-radius: $scrollthumb-border-radius
  border: $scrollthumb-border

*::-webkit-scrollbar
  width: 8px

*::-webkit-scrollbar-track
  border-radius: 8px

*::-webkit-scrollbar-thumb
  height: 56px
  border-radius: 8px
  border: 4px solid transparent
  background-clip: content-box
  background-color: #888

*::-webkit-scrollbar-thumb:hover
  background-color: #555

.ant-select-selection-item
  min-height: 55px !important
