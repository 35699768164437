$report-card-col: #A19EF5
$rp-card-radius: 14px

.is-report-button
  background-color: $report-card-col !important
  color: white !important
  border: none
.is-report-button.is-active
  background-color: white !important
  color: $report-card-col !important
  &:focus
    box-shadow: none

.report-card
  border-radius: $rp-card-radius !important
  background-color: $report-card-col !important

.report-card-head
  border-top-right-radius: $rp-card-radius !important
  border-top-left-radius: $rp-card-radius !important

.report-card-content
  min-height: 40vh !important
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  background-color: #FFFFFF
  border-radius: $rp-card-radius !important

.is-icon-up
  color: #4FD144 !important
.is-icon-down
  color: #D14444 !important
.is-icon-eq
  color: dimgray !important