.reactMainModalPlayer
  top: 0
  border-radius: 20px !important
  text-align: left
  vertical-align: middle
  max-width: 900px !important
  width: 900px !important
  padding: 20px !important
  max-height: 80vh !important
  min-height: 400px !important
  overflow: hidden
  z-index: 300 !important

.playerDivComponent
  width: 100% !important
  max-height: 800px !important

.playerImage
  max-width: 400px !important
  max-height: 600px !important
  width: auto
  height: auto