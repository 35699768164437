.loginContainer {
  background: url("../../Common/Images/auth/authBackground.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 25px 30px 25px;
  min-height: 100vh;
}

.authContainer {
  background-color: white;
  border-radius: 8px;
  padding: 30px 40px 30px 40px;
  margin-top: 20px;
}
.registerSetupContainer{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.authHeader {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-top: 15px;
}
.authImage{
    margin-top: -180px;
}
.authLabel {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px !important;
}
.authInput {
  border-radius: 8px !important;
  border: 3px solid black !important;
  padding: 25px 10px !important;
  background: #f5f5f5 !important;
  box-shadow: none !important;
}

.forgotPassword {
  color: #006daa;
  font-family: "Roboto", sans-serif;
  font-size: 15px !important;
  font-weight: 500;
}
.authSubmitBtn {
  border-radius: 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.14);
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  padding: 22px 25px !important;
}
.authSubmitBtn:hover {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.28);
  background: #006daa;
  border: 1px solid transparent;
}
.authSubmitBtn:focus {
  background: #07476a !important;
}
.orText {
  font-family: "Inter", sans-serif;
  font-size: 15px !important;
  font-weight: 600;
}
.forgotText {
  color: #006daa;
  font-family: "Roboto", sans-serif;
  font-size: 15px !important;
  font-weight: 500;
}
.nav-container{
  background: #FFFFFF;
box-shadow: 0px 12px 10px rgba(76, 73, 129, 0.14);
padding: 30px 20px;
}
.bgColumn{
  background: url('../../Common/Images/auth/authBackground.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 20px 50px 20px !important;
  min-height: 85vh !important;
}
/* .registerSetupContainer{
  padding: 0px !important;
} */
.setupHeader{
  color: #111A22;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
.setupPoints{
  font-family: 'Open Sans', sans-serif;
  color: #111A22;
  font-size: 15px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  
}
.extra{
margin-top: 70px;
}
.setupPointsBox{
  margin-top: 100px !important;
}
.tellUsCol{
  background: #F3F3F3;
  padding: 80px 50px !important;
}
.tellUsColHeader{
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 27px !important;
}
.tellUsLabel{
  font-family: "Roboto", sans-serif;
  color: #737c85 !important;
  font-weight: 500;
  font-size: 20px !important;
  margin-left: 12px;
}
.tellUsInput{
  border: 5px solid black !important;
  border-radius: 5px !important;
  background-color: #f5f5f5;

}
.tellUsFormContainer{
 margin-top: 50px;
 padding: 10px 50px !important;
}
.tellUsPointsInfo{
  font-family: "Roboto", sans-serif;
  color: #111A22;
  font-weight: 500;
}
.radioText{
  font-family: "Roboto", sans-serif !important;
  color: #111A22 !important;
  font-weight: 500 !important;
}
.tellUsSubmitBtn{
  background: #006DAA !important;
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.14) !important;
border-radius: 6px !important;
font-family: "Inter", sans-serif;
font-size: 12px !important;
font-weight: 700;
padding: 25px 25px !important;
}
.tellUsSubmitBtn:hover {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.28);
  background: #006daa;
  border: 1px solid transparent;
}
.tellUsSubmitBtn:focus {
  background: #07476a !important;
}
.control{
  display: flex !important;
  align-items: center;
}
.radioControl{
display: flex;
  flex-direction: column !important;
  
}
.iconBox{
  border: 3px solid black !important;
  padding: 13px 0px 13px 0px !important;
  border-left: none !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color:#f5f5f5;
  

}

.fas:hover{
  cursor: pointer !important;
}
.passInput{
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
@media only screen and (max-width: 1023px) {
    .authImage{
        margin-top: -50px;
    }
   }
@media only screen and (max-width: 768px) {
   .authImage{
       margin-top: -50px;
   }
   .authContainer{
       padding: 30px 15px 20px 15px ;
   }
   .bgColumn{
    padding: 10px 15px 20px 15px !important;
    min-height: 0vh !important;
  }
  .tellUsCol{
    padding: 30px 20px !important;
   }
  .extra{
    margin-top: 30px;
  }
 
  }
 

  @media only screen and (max-width: 480px) {
    .nav-container{
      padding: 12px 20px !important;
    }
    .authImage{
        margin-top: -50px;
    }
    .authContainer{
        padding: 30px 15px 20px 15px ;
    }
    .bgColumn{
      padding: 20px 10px !important;
    }
    .setupPointsBox{
      margin-top: 25px !important;
    }
    .tellUsFormContainer{
      margin-top: 20px;
      padding: 10px 0px !important;
     }
     .bgColumn{
       padding: 10px 10px 20px 10px !important;
       min-height: 0vh !important;
     }
     .tellUsCol{
      padding: 30px 20px !important;
     }
     .tellUsColHeader{
       font-size: 22px !important;
     }
     .tellUsLabel{
       font-size: 15px !important;
     }
     .navbarImage{
       height: 40px !important;
     }
   }
  @media only screen and (min-width: 1024px) and (max-width:1400px) {
    .authImage{
        margin-top: -50px;
    }

   }