.titleButton
    font-weight: 600
    background-color: #111A22 !important
    border-radius: 5px !important
    font-size: 13px !important
    padding: 20px 15px !important

.tableTitleButton
  @extend .titleButton
.tableTitleButton.mr8
  margin-right: 60px !important

.peoplesDataSearchBox
    background-color: #202F3C !important
    padding: 13px
    display: flex
    justify-content: space-between !important
    border-radius: 5px !important

.proplesDataUploadBtn, .peoplesDataAddFilterBtn
    background-color: white !important
    color: #1F2D3A !important
    font-family: 'Roboto', sans-serif
    font-weight: 500 !important
    border-radius: 5px !important

.peoplesDataSearch
    color: white !important
    background-color: #111A22 !important
    min-width: 600px !important
    border: none !important
    &::placeholder
        color: white !important

.peoplesDataTitleSpan
    display: flex !important
    align-items: center !important

.peoplesDataFuncBtns
    background-color: white !important
    color: #293D50 !important
    border-radius: 5px !important
    font-family: 'Roboto', sans-serif
    font-weight: 500 !important

.peoplesDataTitleBox
    display: flex !important
    align-items: center !important
    justify-content: space-between !important

.filterContainer
    background-color: white !important
    color: black !important
    font-size: 15px !important
    font-family: 'Roboto', sans-serif
    font-weight: 400 !important

.rightSiderHeader
    font-family: 'Roboto',sans-serif !important
    color: #111A22 !important
    font-weight: 600

.ant-menu-item-selected
    background-color: gray !important

.peopleTable
    min-width: 300px !important

@media only screen and (min-width: 1081px) and (max-width: 1440px)
    .peoplesDataSearch
        min-width: 500px !important

@media only screen and (min-width: 769px) and (max-width: 1080px)
    .peoplesDataSearch
        min-width: 400px !important

@media only screen and (max-width: 768px)
    .peoplesDataSearch
        min-width: 300px !important